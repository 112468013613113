module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-analytics/gatsby-browser.js'),
      options: {"plugins":[],"writeKey":"uUc2nauXG9pfCgH94XyXwnK46ojjzzcS"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Spectatin","short_name":"Spectatin","start_url":"/","background_color":"#fff","display":"minimal-ui","icon":"src/images/logos/spectatin-icon-48x48.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9026fd02179659bb91539fc07d3e12bd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
